import {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";

import LocalStorageService from "@services/localStorage";
import ChatService from "@services/api/chat";
import { UserContext } from "@contexts/User";
import { ChatContext } from "@contexts/Messages";
import ProfileService from "@services/api/profile";
import { generateUUID } from "@utils/helpers";
import ResourceService from "@services/api/resource";
import {
  CHAT_MESSAGE_TYPES,
  SUPER_ADMIN_SENDER_NAME,
  USER_ROLES,
} from "@utils/consts";
import chatGraphqlInstance from "@services/api/chat.graphql";
import { useLocation } from "react-router-dom";
import profileGraphqlInstance from "@services/api/profile.graphql";
import { GlobalChatContext } from "@contexts/GlobalMessages";
import { AnonymousLiveChatContext } from "@contexts/AnonymousLiveChat";
import { StudentAnonymousLiveChatContext } from "@contexts/StudentAnonymousLiveChat";

const prepareChatRoom = async (
  chatRoomParam,
  userProfile,
  populateChatParticipantProfiles
) => {
  const { messageCount, ...chatRoom } = { ...chatRoomParam };
  let unreadMessageCount = messageCount ? Number(messageCount) : 0;

  if (chatRoom.participants) {
    const currentUserParticipant = chatRoom.participants.find(
      (participant) => participant.userId === userProfile.userId
    );
    if (currentUserParticipant) {
      const sendMessageCount = currentUserParticipant.sendMessageCount
        ? Number(currentUserParticipant.sendMessageCount)
        : 0;
      const readMessageCount = currentUserParticipant.readMessageCount
        ? Number(currentUserParticipant.readMessageCount)
        : 0;
      unreadMessageCount =
        unreadMessageCount - sendMessageCount - readMessageCount;

      chatRoom.participants = chatRoom.participants.filter(
        (participant) => participant.userId !== userProfile.userId
      );

      chatRoom.isSelfParticipant = true;
    }

    if (populateChatParticipantProfiles) {
      const participantProfiles = chatRoom.participants.map((participant) =>
        ProfileService.getProfileByUserId(
          participant.accountId,
          participant.userId
        )
      );
      chatRoom.participantProfiles = await Promise.all(participantProfiles);
    }
  }

  if (chatRoom.lastMessage) {
    chatRoom.lastMessage = {
      ...chatRoom.lastMessage,
      sentAt: Number(chatRoom.lastMessage.sentAt),
    };
  } else {
    chatRoom.lastMessage = {
      sentAt: Number(chatRoom.createdAt),
      messageText: "",
      type: CHAT_MESSAGE_TYPES.TEXT,
    };
  }

  return {
    ...chatRoom,
    unreadMessageCount,
    profile:
      chatRoom?.accountId === null
        ? {}
        : (chatRoom.participantProfiles && chatRoom.participantProfiles[0]) ||
          [],
  };
};

const prepareChatMessage = async (chatMessage) => {
  if (
    (chatMessage.type === CHAT_MESSAGE_TYPES.TEXT_ATTACHMENT ||
      chatMessage.type === CHAT_MESSAGE_TYPES.ATTACHMENT) &&
    !chatMessage.attachments?.length
  ) {
    const attachments = (
      await ResourceService.getResourcesByEntity(
        chatMessage.globalChatRoomId
          ? chatMessage.globalChatRoomId
          : chatMessage.senderName === SUPER_ADMIN_SENDER_NAME
          ? chatMessage.chatRoomId
          : chatMessage.accountId,
        chatMessage.id,
        "chatAttachment",
        chatMessage.hasOwnProperty("globalChatRoomId"),
        chatMessage.__typename === "LiveChatChatMessage"
      )
    ).map((v) => ({ ...v, size: Number(v.size) }));

    return {
      ...chatMessage,
      attachments,
      sentAt: chatMessage?.sentAt
        ? Number(chatMessage.sentAt)
        : Number(chatMessage.sendAt),
    };
  }

  return {
    ...chatMessage,
    sentAt: chatMessage?.sentAt
      ? Number(chatMessage.sentAt)
      : Number(chatMessage.sendAt),
  };
};

export const useChatList = (
  {
    isPopulateChatParticipants = false,
    isPopulateChatParticipantProfiles = false,
    isPopulateLastMessage = false,
    allowReadMessageEventEmitting = false,
    isCacheEnabled = false,
    chatListRefresh = false,
  } = {},
  filterFn
) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatId = query.get("chatId");

  const [chatList, setChatList] = useState(() => {
    if (isCacheEnabled) {
      const cache = LocalStorageService.getChatRooms();

      if (cache?.length) {
        return cache;
      }
    }

    return undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const pendingNewChats = useRef([]);
  const { profile } = useContext(UserContext);
  const { socket, openedChatRoomId } = useContext(ChatContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();
  const isCounsellor = profile?.type === USER_ROLES.COUNSELLOR_ROLE;

  useEffect(() => {
    if (isCacheEnabled && chatList && isCacheMounted.current) {
      LocalStorageService.setChatRooms(chatList);
      return;
    }

    isCacheMounted.current = true;
  }, [chatList]);

  const fetchChatList = useCallback(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    let chatRoomsResponse = [];
    if (isCounsellor) {
      chatRoomsResponse =
        await chatGraphqlInstance.getCurrentAccountChatRooms();
    } else {
      chatRoomsResponse = await ChatService.getChatRoomsByUserId(
        profile.accountId,
        profile.userId,
        isPopulateLastMessage,
        isPopulateChatParticipants
      );
    }

    const chatRoomsResponseFiltered = chatRoomsResponse?.filter((chatRoom) => {
      if (chatRoom?.organizerName === SUPER_ADMIN_SENDER_NAME) {
        return true;
      }
      return chatRoom?.participants?.length >= 1;
    });

    let preparedChatRooms = chatRoomsResponseFiltered.map((chatRoom) =>
      prepareChatRoom(chatRoom, profile, isPopulateChatParticipantProfiles)
    );

    preparedChatRooms = (await Promise.all(preparedChatRooms))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) =>
        a.lastMessage.sentAt < b.lastMessage.sentAt
          ? 1
          : b.lastMessage.sentAt < a.lastMessage.sentAt
          ? -1
          : 0
      );
    if (!isUnmounted.current) {
      setChatList(preparedChatRooms);
      setIsLoading(false);
    }
  }, [profile, chatListRefresh]);

  useEffect(() => {
    (async () => {
      fetchChatList();
    })();
  }, [profile, chatListRefresh]);

  useEffect(() => {
    if (
      !socket?.current ||
      socket.current.disconnected ||
      !profile ||
      !openedChatRoomId ||
      !allowReadMessageEventEmitting
    ) {
      return;
    }

    if (!chatId) {
      return;
    }
    socket.current.emit("readMessages", openedChatRoomId);
  }, [openedChatRoomId, socket]);

  useEffect(() => {
    if (!socket?.current || socket.current.disconnected || !profile) {
      return;
    }

    const newChatRoomHandler = async (chatRoom) => {
      if (chatRoom?.accountId !== profile.accountId) {
        return;
      }

      //  For now, we are not allowing chat rooms with only one participant
      // if (chatRoom.participants.length < 2) {\
      //   pendingNewChats.current = [...pendingNewChats.current, chatRoom];
      //   return;
      // }

      const preparedChatRoom = await prepareChatRoom(
        chatRoom,
        profile,
        isPopulateChatParticipantProfiles
      );

      if (isUnmounted.current) {
        return;
      }

      setChatList((prev) =>
        prev ? [preparedChatRoom, ...prev] : [preparedChatRoom]
      );
    };

    const newChatParticipantHandler = async (chatParticipant) => {
      // this is used so we don't create new item in the list with one participant while adding participant
      const pendingChat = pendingNewChats.current.find(
        (v) => v.id === chatParticipant.chatRoomId
      );
      if (pendingChat) {
        if (chatList.find((chat) => chat.id === pendingChat.id)) {
          pendingNewChats.current = pendingNewChats.current.filter(
            (v) => v.id !== pendingChat.id
          );
          return;
        }

        const newChat = {
          ...pendingChat,
          participants: pendingChat.participants
            ? [...pendingChat.participants, chatParticipant]
            : [chatParticipant],
        };

        const chatRoomsResponse = await ChatService.getChatRoomsByUserId(
          profile.accountId,
          profile.userId,
          isPopulateLastMessage,
          isPopulateChatParticipants
        );

        const chatRoomsResponseFiltered = chatRoomsResponse.filter(
          (chatRoom) => chatRoom.participants.length > 1
        );
        let preparedChatRooms = await chatRoomsResponseFiltered.map(
          (chatRoom) =>
            prepareChatRoom(
              chatRoom,
              profile,
              isPopulateChatParticipantProfiles
            )
        );
        preparedChatRooms = (await Promise.all(preparedChatRooms))
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) =>
            a.lastMessage.sentAt < b.lastMessage.sentAt
              ? 1
              : b.lastMessage.sentAt < a.lastMessage.sentAt
              ? -1
              : 0
          );

        if (filterFn && typeof filterFn === "function") {
          preparedChatRooms = filterFn(preparedChatRooms);
        }

        if (!isUnmounted.current) {
          setChatList(preparedChatRooms);
          setIsLoading(false);
        }
        // setChatList((prev) => [preparedChatRoom, ...prev]);
        pendingNewChats.current = pendingNewChats.current.filter(
          (v) => v.id !== newChat.id
        );
      }
    };

    const newMessageHandler = (chatMessage) => {
      const newMessageChat = chatList.find(
        (chat) => chat.id === chatMessage.chatRoomId
      );
      if (!newMessageChat) {
        return;
      }

      if (chatMessage.senderId !== profile.userId) {
        const params = new URLSearchParams(window.location.search);
        const chatIdParams = params.get("chatId");
        if (chatMessage.chatRoomId === openedChatRoomId && chatIdParams) {
          if (allowReadMessageEventEmitting) {
            socket.current.emit("readMessages", chatMessage.chatRoomId);
          }
        } else {
          newMessageChat.unreadMessageCount += 1;
        }
      }

      newMessageChat.lastMessage = {
        ...chatMessage,
        sentAt: Number(chatMessage.sentAt),
      };

      if (!isUnmounted.current) {
        setChatList((prev) => [
          newMessageChat,
          ...prev.filter((chat) => chat.id !== chatMessage.chatRoomId),
        ]);
      }
    };

    const messagesReadHandler = (readEvent) => {
      if (!chatList) {
        return;
      }

      const chatListCopy = [...chatList];

      const readMessagesChat = chatListCopy.find(
        (chat) => chat.id === readEvent.chatRoomId
      );
      if (!readMessagesChat || readMessagesChat.unreadMessageCount === 0) {
        return;
      }

      readMessagesChat.unreadMessageCount = 0;

      if (!isUnmounted.current) {
        setChatList(chatListCopy);
      }
    };

    const chatRoomDeletedHandler = async (chatRoomId) => {
      if (!chatList.some((chat) => chat.id === chatRoomId)) {
        return;
      }

      if (!isUnmounted.current) {
        setChatList((prev) => prev.filter((chat) => chat.id !== chatRoomId));
      }
    };

    socket.current.on("newChatRoom", newChatRoomHandler);
    socket.current.on("newChatParticipant", newChatParticipantHandler);
    socket.current.on("newMessage", newMessageHandler);
    socket.current.on("messagesRead", messagesReadHandler);
    socket.current.on("chatRoomDeleted", chatRoomDeletedHandler);
    socket.current.on("ParticipantChatListUpdate", fetchChatList);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off("newChatRoom", newChatRoomHandler);
      socket.current.off("newChatParticipant", newChatParticipantHandler);
      socket.current.off("newMessage", newMessageHandler);
      socket.current.off("messagesRead", messagesReadHandler);
      socket.current.off("chatRoomDeleted", chatRoomDeletedHandler);
      socket.current.off("ParticipantChatListUpdate", fetchChatList);
    };
  }, [
    chatList,
    socket,
    profile,
    openedChatRoomId,
    isPopulateChatParticipantProfiles,
    allowReadMessageEventEmitting,
  ]);

  const updateChatRoom = useCallback(
    async (accountId, chatRoom, cb) => {
      const chatListCopy = [...chatList];
      const updatingChatIndex = chatListCopy.findIndex(
        (chat) => chat.id === chatRoom.id
      );
      if (updatingChatIndex === -1) {
        return;
      }

      const updateResponse = await ChatService.updateChatRoom(
        accountId,
        chatRoom
      );
      chatListCopy[updatingChatIndex] = {
        ...chatListCopy[updatingChatIndex],
        isArchived: updateResponse.isArchived,
        organizerName: updateResponse.organizerName,
      };

      setChatList(chatListCopy);
      if (cb && typeof cb === "function") {
        cb();
      }
    },
    [chatList]
  );

  return useMemo(() => {
    // TODO: investigate why sometimes there are chat duplicates
    const chats = chatList
      ? chatList.filter(
          (chat, index) => chatList.findIndex((c) => c.id === chat.id) === index
        )
      : [];
    return [chats, { isLoading, updateChatRoom }];
  }, [chatList, isLoading]);
};

export const useChatMessages = ({
  chatRoomId,
  filterFn,
  chatMessagesRefresh = false,
  isGlobalChat = false,
  isDataFetchingEnabled = true,
}) => {
  if (!isDataFetchingEnabled) {
    return [undefined, { isLoading: false }];
  }
  const [chatMessages, setChatMessages] = useState(() => {
    let cache = undefined;
    if (isGlobalChat) {
      cache = LocalStorageService.getGlobalChatRoomMessages(chatRoomId);
    } else {
      cache = LocalStorageService.getChatRoomMessages(chatRoomId);
    }
    return cache ?? undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const [updatedReadBadgeId, setUpdatedReadBadgeId] = useState();
  const { profile } = useContext(UserContext);
  const { socket } = useContext(ChatContext);
  const { socketForGlobalMessage } = useContext(GlobalChatContext);
  const [initial, setInitial] = useState(true);
  const [isDataOver, setIsDataOver] = useState(false);
  const [date, setDate] = useState(null);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  useEffect(() => {
    if (chatMessages && isCacheMounted.current) {
      if (isGlobalChat) {
        LocalStorageService.setGlobalChatRoomMessages(chatRoomId, chatMessages);
      } else {
        LocalStorageService.setChatRoomMessages(chatRoomId, chatMessages);
      }
      return;
    }

    isCacheMounted.current = true;
  }, [chatMessages]);

  const handleChatMessages = async (date = null) => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let chatRoomMessagesResponse = [];
    if (isGlobalChat) {
      chatRoomMessagesResponse = await chatGraphqlInstance.getChatRoomMessages(
        chatRoomId,
        date
      );
    } else {
      chatRoomMessagesResponse = await ChatService.getChatRoomMessages(
        profile.accountId,
        chatRoomId,
        date
      );
    }
    if (chatRoomMessagesResponse.length < 20) {
      setIsDataOver(true);
    }
    const preparedChatMessagesPromises = chatRoomMessagesResponse.map(
      (chatMessage) => prepareChatMessage(chatMessage)
    );
    let preparedChatMessages = (await Promise.all(preparedChatMessagesPromises))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.sentAt < b.sentAt ? 1 : b.sentAt < a.sentAt ? -1 : 0));

    if (filterFn && typeof filterFn === "function") {
      preparedChatMessages = filterFn(preparedChatMessages);
    }

    if (!isUnmounted.current) {
      setChatMessages((prev) => {
        if (initial) {
          return preparedChatMessages;
        }
        return [...prev, ...preparedChatMessages];
      });
      setIsLoading(false);
    }
  };

  const handleLoadMoreMessages = async (newDate) => {
    setDate(newDate);
    setInitial(false);
  };

  useEffect(() => {
    if (date !== null && !initial) {
      handleChatMessages(date);
    }
  }, [initial, date]);

  useEffect(() => {
    (async () => {
      handleChatMessages();
      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [profile, chatRoomId, chatMessagesRefresh]);

  useEffect(() => {
    if (isGlobalChat) {
      if (
        !socketForGlobalMessage?.current ||
        socketForGlobalMessage.current.disconnected
      ) {
        return;
      }
    } else {
      if (!socket?.current || socket.current.disconnected) {
        return;
      }
    }

    const newMessageHandler = async (chatMessage) => {
      if (isGlobalChat) {
        if (chatMessage.globalChatRoomId !== chatRoomId) {
          return;
        }
      } else {
        if (chatMessage.chatRoomId !== chatRoomId) {
          return;
        }
      }

      const existingMessage = chatMessages?.find(
        (v) => v.id === chatMessage.id
      );

      if (existingMessage) {
        if (!existingMessage.isPending) {
          return;
        }

        const preparedMessage = await prepareChatMessage({
          ...chatMessage,
          attachments: existingMessage.attachments,
          isPending: false,
        });
        if (!isUnmounted.current) {
          setChatMessages((prev) => [
            preparedMessage,
            ...prev.filter((v) => v.id !== preparedMessage.id),
          ]);
        }

        return;
      }

      const preparedNewMessage = await prepareChatMessage(chatMessage);

      if (!isUnmounted.current) {
        setChatMessages((prev) =>
          prev ? [preparedNewMessage, ...prev] : [preparedNewMessage]
        );
      }
    };

    const participantDeletedHandler = () => {
      handleChatMessages();
    };

    socket.current.on("newMessage", newMessageHandler);
    socketForGlobalMessage.current.on(
      "newGlobalChatMessage",
      newMessageHandler
    );
    socket.current.on(
      "participantMessagesRead",
      participantMessagesReadHandler
    );
    socket.current.on("participantDeleteMessage", participantDeletedHandler);
    socketForGlobalMessage.current.on(
      "globalParticipantDeleteMessage",
      participantDeletedHandler
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off("newMessage", newMessageHandler);
      socketForGlobalMessage.current.off(
        "newGlobalChatMessage",
        newMessageHandler
      );
      socket.current.off(
        "participantMessagesRead",
        participantMessagesReadHandler
      );
      socket.current.off("participantDeleteMessage", participantDeletedHandler);
      socketForGlobalMessage.current.off(
        "globalParticipantDeleteMessage",
        participantDeletedHandler
      );
    };
  }, [chatMessages, socket, profile, chatRoomId, socketForGlobalMessage]);

  const createChatMessage = useCallback(
    async (newMessage, cb) => {
      const messageId = generateUUID();
      const messageAttachments = newMessage.attachments?.length
        ? newMessage.attachments.map((attachment) => ({
            id: attachment.fileName.split(".")[0],
            accountId: newMessage.accountId,
            entityId: messageId,
            entityType: "chatAttachment",
            resourceName: attachment.originalFileName.split(".")[0],
            resourceType: attachment.fileName.split(".").pop(),
            contentType: attachment.mimetype,
            size: attachment.size,
          }))
        : [];

      const preparedNewMessage = await prepareChatMessage({
        id: messageId,
        ...newMessage,
        attachments: messageAttachments,
      });

      setChatMessages((prev) =>
        prev
          ? [{ ...preparedNewMessage, isPending: true }, ...prev]
          : [{ ...preparedNewMessage, isPending: true }]
      );

      const attachmentsPromises = messageAttachments.map((attachment) =>
        ResourceService.createResource(preparedNewMessage.accountId, attachment)
      );

      await Promise.all(attachmentsPromises);
      await ChatService.createChatRoomMessage(preparedNewMessage.accountId, {
        id: preparedNewMessage.id,
        accountId: preparedNewMessage.accountId,
        senderName: preparedNewMessage.senderName,
        senderId: preparedNewMessage.senderId,
        chatRoomId: preparedNewMessage.chatRoomId,
        messageText: preparedNewMessage.messageText,
        type: preparedNewMessage.type,
        sentAt: preparedNewMessage.sentAt,
      });

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    [chatMessages]
  );

  const createGlobalChatMessage = useCallback(
    async (newMessage, cb) => {
      const messageId = generateUUID();
      const messageAttachments = newMessage.attachments?.length
        ? newMessage.attachments.map((attachment) => ({
            id: attachment.fileName.split(".")[0],
            accountId: newMessage.globalChatRoomId,
            entityId: messageId,
            entityType: "chatAttachment",
            resourceName: attachment.originalFileName.split(".")[0],
            resourceType: attachment.fileName.split(".").pop(),
            contentType: attachment.mimetype,
            size: attachment.size,
            isGlobalChat: true,
          }))
        : [];

      const preparedNewMessage = await prepareChatMessage({
        id: messageId,
        ...newMessage,
        attachments: messageAttachments,
      });

      setChatMessages((prev) =>
        prev
          ? [{ ...preparedNewMessage, isPending: true }, ...prev]
          : [{ ...preparedNewMessage, isPending: true }]
      );

      const attachmentsPromises = messageAttachments.map((attachment) =>
        ResourceService.createResource(
          preparedNewMessage.globalChatRoomId,
          attachment
        )
      );

      await Promise.all(attachmentsPromises);
      await chatGraphqlInstance.createChatChatMessageGlobal({
        id: preparedNewMessage.id,
        senderName: preparedNewMessage.senderName,
        senderId: preparedNewMessage.senderId,
        globalChatRoomId: preparedNewMessage.globalChatRoomId,
        messageText: preparedNewMessage.messageText,
        type: preparedNewMessage.type,
        sentAt: preparedNewMessage.sentAt,
      });

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    [chatMessages]
  );

  const participantMessagesReadHandler = async (readEvent) => {
    if (chatMessages && readEvent.chatRoomId === chatRoomId) {
      await chatGraphqlInstance
        .getUnreadMessageCount(
          readEvent.chatRoomId,
          readEvent.accountId,
          readEvent.userId
        )
        .then((res) => {
          const messageData = chatMessages.filter(
            (x) => x.senderId === profile.userId
          );
          if (messageData) {
            setUpdatedReadBadgeId(messageData?.[res]?.id);
          }
        });
    }
  };

  return useMemo(() => {
    // TODO: investigate why sometimes there are message duplicates
    const messages = chatMessages
      ? chatMessages.filter(
          (message, index) =>
            chatMessages.findIndex((m) => m.id === message.id) === index
        )
      : [];
    return [
      messages,
      {
        createChatMessage,
        isLoading,
        updatedReadBadgeId,
        createGlobalChatMessage,
        handleLoadMoreMessages,
        isDataOver,
      },
    ];
  }, [chatMessages, isLoading, updatedReadBadgeId, handleLoadMoreMessages]);
};

export const useChatRoomCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const createChatRoom = useCallback(async (newChat, chatParticipant, cb) => {
    setIsLoading(true);
    const chatRoomResponse = await ChatService.createChatRoom(
      newChat.accountId,
      {
        isArchived: newChat.isArchived,
        organizerName: newChat.organizerName,
        organizerId: newChat.organizerId,
        accountId: newChat.accountId,
      }
    );

    const chatRoomParticipant = await ChatService.createChatRoomParticipant(
      chatRoomResponse.accountId,
      {
        chatRoomId: chatRoomResponse.id,
        accountId: chatRoomResponse.accountId,
        name: chatParticipant.name ?? chatParticipant.username,
        userId: chatParticipant.userId,
      }
    );

    if (!isUnmounted.current) {
      setIsLoading(false);
    }

    if (cb && typeof cb === "function") {
      cb({ ...chatRoomResponse, id: chatRoomParticipant.chatRoomId });
    }
  }, []);

  return [createChatRoom, { isLoading }];
};

export const useChatRoomParticipantCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const createChatRoomParticipant = useCallback(
    async (accountId, chatParticipant, cb) => {
      setIsLoading(true);

      await ChatService.createChatRoomParticipant(accountId, {
        chatRoomId: chatParticipant.chatRoomId,
        accountId: chatParticipant.accountId,
        name: chatParticipant.name,
        userId: chatParticipant.userId,
      });

      if (!isUnmounted.current) {
        setIsLoading(false);
      }

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    []
  );

  return [createChatRoomParticipant, { isLoading }];
};

export const useChatRoomParticipantDelete = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const deleteChatRoomParticipant = useCallback(
    async (accountId, chatRoomId, userId, cb) => {
      setIsLoading(true);

      await ChatService.deleteChatRoomParticipant(
        accountId,
        chatRoomId,
        userId
      );

      if (!isUnmounted.current) {
        setIsLoading(false);
      }

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    []
  );

  return [deleteChatRoomParticipant, { isLoading }];
};

export const useGroupChatRoomCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const createGroupChatRoom = useCallback(
    async (newChat, chatParticipant, cb) => {
      setIsLoading(true);
      const chatRoomResponse = await ChatService.createChatRoom(
        newChat.accountId,
        {
          isArchived: newChat.isArchived,
          organizerName: newChat.organizerName,
          organizerId: newChat.organizerId,
          accountId: newChat.accountId,
          name: newChat.groupName ? newChat.groupName : undefined,
        }
      );

      for (const participant of chatParticipant) {
        await ChatService.createChatRoomParticipant(
          chatRoomResponse.accountId,
          {
            chatRoomId: chatRoomResponse.id,
            accountId: chatRoomResponse.accountId,
            name: participant.name,
            userId: participant.userId,
          }
        );
      }

      if (!isUnmounted.current) {
        setIsLoading(false);
      }

      if (cb && typeof cb === "function") {
        cb(chatRoomResponse);
      }
    },
    []
  );

  return [createGroupChatRoom, { isLoading }];
};

export const useChatRoomDelete = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteChatRoom = useCallback(async (accountId, chatRoomId, cb) => {
    setIsLoading(true);

    await ChatService.deleteChatRoom(accountId, chatRoomId);

    setIsLoading(false);

    if (cb && typeof cb === "function") {
      cb();
    }
  }, []);

  return [deleteChatRoom, { isLoading }];
};

export const useChatAttachments = (
  isGlobalChatRoom = false,
  chatRoomId,
  filterFn
) => {
  const [attachments, setAttachments] = useState();
  const { profile } = useContext(UserContext);
  const { socket } = useContext(ChatContext);
  const { socketForGlobalMessage } = useContext(GlobalChatContext);
  const [isLoading, setIsLoading] = useState(true);

  const isUnmounted = useRef();

  const fetchChatAttachments = useCallback(
    async (isGlobal = false) => {
      if (!profile) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      let chatAttachmentsResponse = [];

      if (isGlobal) {
        chatAttachmentsResponse =
          await chatGraphqlInstance.getAttachmentsByGlobalChatRoomId(
            chatRoomId
          );
      } else {
        chatAttachmentsResponse = await ChatService.getChatRoomAttachments(
          profile.accountId,
          chatRoomId,
          isGlobal
        );
      }

      if (isUnmounted.current) {
        return;
      }

      chatAttachmentsResponse = chatAttachmentsResponse
        .map((attachment) => ({
          ...attachment,
          sentAt: Number(attachment.sentAt),
          attachmentSize: Number(attachment.attachmentSize),
        }))
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) =>
          a.sentAt < b.sentAt ? 1 : b.sentAt < a.sentAt ? -1 : 0
        );

      if (filterFn && typeof filterFn === "function") {
        chatAttachmentsResponse = filterFn(chatAttachmentsResponse);
      }

      setAttachments(chatAttachmentsResponse);
      setIsLoading(false);
    },
    [profile, chatRoomId]
  );

  useEffect(() => {
    fetchChatAttachments(isGlobalChatRoom);
  }, [profile, chatRoomId]);

  useEffect(() => {
    if (isGlobalChatRoom) {
      if (
        !socketForGlobalMessage?.current ||
        socketForGlobalMessage.current.disconnected
      ) {
        return;
      }
    } else {
      if (!socket?.current || socket.current.disconnected) {
        return;
      }
    }

    const newChatAttachmentHandler = async (chatAttachment) => {
      if (
        (chatAttachment?.hasOwnProperty("globalChatRoomId")
          ? chatAttachment?.globalChatRoomId
          : chatAttachment.chatRoomId) !== chatRoomId
      ) {
        return;
      }

      const preparedChatAttachment = {
        ...chatAttachment,
        sentAt: Number(chatAttachment.sentAt),
      };

      if (!isUnmounted.current) {
        setAttachments((prev) =>
          prev ? [preparedChatAttachment, ...prev] : [preparedChatAttachment]
        );
      }
    };

    const attachmentDeleteHandler = async (data) => {
      fetchChatAttachments(data);
    };

    socket.current.on("newChatAttachment", newChatAttachmentHandler);
    socketForGlobalMessage.current.on(
      "newGlobalChatAttachment",
      newChatAttachmentHandler
    );
    socketForGlobalMessage.current.on(
      "globalParticipantDeleteAttachment",
      attachmentDeleteHandler
    );
    socket.current.on("participantDeleteAttachment", attachmentDeleteHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off("newChatAttachment", newChatAttachmentHandler);
      socketForGlobalMessage.current.off(
        "newGlobalChatAttachment",
        newChatAttachmentHandler
      );
      socketForGlobalMessage.current.off(
        "globalParticipantDeleteAttachment",
        attachmentDeleteHandler
      );
      socket.current.off(
        "participantDeleteAttachment",
        attachmentDeleteHandler
      );
    };
  }, [attachments, socket, profile, chatRoomId, socketForGlobalMessage]);

  // TODO: investigate why sometimes there are attachments duplicates
  return useMemo(() => {
    const result = attachments
      ? attachments.filter(
          (attachment, index) =>
            attachments.findIndex(
              (a) =>
                (a.hasOwnProperty("globalChatRoomId")
                  ? a.globalChatRoomId
                  : a.chatRoomId) ===
                  (attachment.hasOwnProperty("globalChatRoomId")
                    ? attachment?.globalChatRoomId
                    : attachment.chatRoomId) &&
                a.attachmentId === attachment.attachmentId
            ) === index
        )
      : [];

    return [result, { isLoading }];
  }, [attachments, isLoading]);
};

export const useGlobalChatRoomCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const createGlobalChatRoom = useCallback(
    async (newChat, chatParticipant, cb) => {
      setIsLoading(true);
      const globalChatRoomResponse =
        await chatGraphqlInstance.createChatChatRoomGlobal({
          isArchived: newChat.isArchived,
          organizerName: newChat.organizerName,
          organizerId: newChat.organizerId,
        });

      await chatParticipant.forEach(
        async (participant) =>
          await chatGraphqlInstance.createChatChatParticipantGlobal({
            name: participant.name,
            userId: participant.userId,
            globalChatRoomId: globalChatRoomResponse.id,
          })
      );

      if (!isUnmounted.current) {
        setIsLoading(false);
      }

      if (cb && typeof cb === "function") {
        cb(globalChatRoomResponse);
      }
    },
    []
  );

  return [createGlobalChatRoom, { isLoading }];
};

export const useGroupChatRoomCreateForGlobal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  const createGroupChatRoomForGlobal = useCallback(
    async (newChat, chatParticipant, cb) => {
      setIsLoading(true);
      const globalChatRoomResponse =
        await chatGraphqlInstance.createChatChatRoomGlobal({
          isArchived: newChat.isArchived,
          organizerName: newChat.organizerName,
          organizerId: newChat.organizerId,
          name: newChat.groupName ? newChat.groupName : undefined,
        });

      await chatParticipant.forEach(
        async (participant) =>
          await chatGraphqlInstance.createChatChatParticipantGlobal({
            name: participant.name,
            userId: participant.userId,
            globalChatRoomId: globalChatRoomResponse.id,
          })
      );

      if (!isUnmounted.current) {
        setIsLoading(false);
      }

      if (cb && typeof cb === "function") {
        cb(globalChatRoomResponse);
      }
    },
    []
  );

  return [createGroupChatRoomForGlobal, { isLoading }];
};

const prepareGlobalChatRoom = async (
  chatRoomParam,
  userProfile,
  populateChatParticipantProfiles
) => {
  const { messageCount, ...chatRoom } = { ...chatRoomParam };
  let unreadMessageCount = messageCount ? Number(messageCount) : 0;

  if (chatRoom.participants) {
    const currentUserParticipant = chatRoom.participants.find(
      (participant) => participant.userId === userProfile.userId
    );
    if (currentUserParticipant) {
      const sendMessageCount = currentUserParticipant.sendMessageCount
        ? Number(currentUserParticipant.sendMessageCount)
        : 0;
      const readMessageCount = currentUserParticipant.readMessageCount
        ? Number(currentUserParticipant.readMessageCount)
        : 0;
      unreadMessageCount =
        unreadMessageCount - sendMessageCount - readMessageCount;

      chatRoom.participants = chatRoom.participants.filter(
        (participant) => participant.userId !== userProfile.userId
      );
    }

    if (populateChatParticipantProfiles) {
      const participantProfiles = chatRoom.participants.map((participant) =>
        profileGraphqlInstance.getAllAccountPerson({
          userId: participant.userId,
        })
      );
      chatRoom.participantProfiles = await Promise.all(participantProfiles);
    }
  }

  if (chatRoom.lastMessage) {
    chatRoom.lastMessage = {
      ...chatRoom.lastMessage,
      sentAt: Number(chatRoom.lastMessage.sentAt),
    };
  } else {
    chatRoom.lastMessage = {
      sentAt: Number(chatRoom.createdAt),
      messageText: "",
      type: CHAT_MESSAGE_TYPES.TEXT,
    };
  }

  return {
    ...chatRoom,
    unreadMessageCount,
    profile:
      (chatRoom.participantProfiles &&
        chatRoom.participantProfiles?.flatMap((v) => v))[0] || [],
  };
};

export const useGlobalChatList = (
  {
    isPopulateChatParticipants = false,
    isPopulateChatParticipantProfiles = false,
    isPopulateLastMessage = false,
    allowReadMessageEventEmitting = false,
    isCacheEnabled = false,
    chatListRefresh = false,
  } = {},
  filterFn
) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatId = query.get("chatId");

  const [globalChatList, setGlobalChatList] = useState(() => {
    if (isCacheEnabled) {
      const cache = LocalStorageService.getChatRooms();

      if (cache?.length) {
        return cache;
      }
    }

    return undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const pendingNewChats = useRef([]);
  const { profile } = useContext(UserContext);
  const { socketForGlobalMessage, openedChatRoomId } =
    useContext(GlobalChatContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();
  const isCounsellor = profile?.type === USER_ROLES.COUNSELLOR_ROLE;

  if (!isCounsellor) {
    return [[], { isLoading: false }];
  }

  useEffect(() => {
    if (isCacheEnabled && globalChatList && isCacheMounted.current) {
      LocalStorageService.setChatRooms(globalChatList);
      return;
    }

    isCacheMounted.current = true;
  }, [globalChatList]);

  const fetchGlobalChatList = useCallback(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const chatRoomsResponse = await chatGraphqlInstance.getChatRoomsByUserId({
      userId: profile.userId,
      populateLastMessage: isPopulateLastMessage,
      populateChatParticipants: isPopulateChatParticipants,
    });

    const chatRoomsResponseFiltered = chatRoomsResponse.filter(
      (chatRoom) => chatRoom?.participants?.length > 1
    );

    let preparedChatRooms = chatRoomsResponseFiltered.map((chatRoom) =>
      prepareGlobalChatRoom(
        chatRoom,
        profile,
        isPopulateChatParticipantProfiles
      )
    );

    preparedChatRooms = (await Promise.all(preparedChatRooms))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) =>
        a.lastMessage.sentAt < b.lastMessage.sentAt
          ? 1
          : b.lastMessage.sentAt < a.lastMessage.sentAt
          ? -1
          : 0
      );

    if (filterFn && typeof filterFn === "function") {
      preparedChatRooms = filterFn(preparedChatRooms);
    }

    if (!isUnmounted.current) {
      setGlobalChatList(preparedChatRooms);
      setIsLoading(false);
    }
  }, [profile, chatListRefresh]);

  useEffect(() => {
    (async () => {
      fetchGlobalChatList();
    })();
  }, [profile, chatListRefresh]);

  useEffect(() => {
    if (
      !socketForGlobalMessage?.current ||
      socketForGlobalMessage.current.disconnected ||
      !profile ||
      !openedChatRoomId ||
      !allowReadMessageEventEmitting
    ) {
      return;
    }

    if (!chatId) {
      return;
    }

    socketForGlobalMessage.current.emit("readGlobalMessages", openedChatRoomId);
  }, [openedChatRoomId, socketForGlobalMessage]);

  useEffect(() => {
    if (
      !socketForGlobalMessage?.current ||
      socketForGlobalMessage.current.disconnected ||
      !profile
    ) {
      return;
    }

    const newChatRoomHandler = async (chatRoom) => {
      if (globalChatList.find((chat) => chat.id === chatRoom.id)) {
        return;
      }

      if (chatRoom.participants.length < 2) {
        pendingNewChats.current = [...pendingNewChats.current, chatRoom];
        return;
      }

      const preparedChatRoom = await prepareGlobalChatRoom(
        chatRoom,
        profile,
        isPopulateChatParticipantProfiles
      );

      if (isUnmounted.current) {
        return;
      }

      setGlobalChatList((prev) =>
        prev ? [preparedChatRoom, ...prev] : [preparedChatRoom]
      );
    };

    const newChatParticipantHandler = async (chatParticipant) => {
      // this is used so we don't create new item in the list with one participant while adding participant
      const pendingChat = pendingNewChats.current.find(
        (v) => v.id === chatParticipant.globalChatRoomId
      );

      if (pendingChat) {
        if (globalChatList.find((chat) => chat.id === pendingChat.id)) {
          pendingNewChats.current = pendingNewChats.current.filter(
            (v) => v.id !== pendingChat.id
          );
          return;
        }

        const newChat = {
          ...pendingChat,
          participants: pendingChat.participants
            ? [...pendingChat.participants, chatParticipant]
            : [chatParticipant],
        };

        const chatRoomsResponse =
          await chatGraphqlInstance.getChatRoomsByUserId({
            userId: profile.userId,
            populateLastMessage: isPopulateLastMessage,
            populateChatParticipants: isPopulateChatParticipants,
          });

        const chatRoomsResponseFiltered = chatRoomsResponse.filter(
          (chatRoom) => chatRoom.participants.length > 1
        );

        let preparedChatRooms = await chatRoomsResponseFiltered.map(
          (chatRoom) =>
            prepareGlobalChatRoom(
              chatRoom,
              profile,
              isPopulateChatParticipantProfiles
            )
        );
        preparedChatRooms = (await Promise.all(preparedChatRooms))

          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) =>
            a.lastMessage.sentAt < b.lastMessage.sentAt
              ? 1
              : b.lastMessage.sentAt < a.lastMessage.sentAt
              ? -1
              : 0
          );

        if (filterFn && typeof filterFn === "function") {
          preparedChatRooms = filterFn(preparedChatRooms);
        }

        if (!isUnmounted.current) {
          setGlobalChatList(preparedChatRooms);
          setIsLoading(false);
        }
        // setChatList((prev) => [preparedChatRoom, ...prev]);
        pendingNewChats.current = pendingNewChats.current.filter(
          (v) => v.id !== newChat.id
        );
      }
    };

    const newMessageHandler = async (chatMessage) => {
      const newMessageChat = globalChatList.find(
        (chat) => chat.id === chatMessage.globalChatRoomId
      );

      if (!newMessageChat) {
        return;
      }

      if (chatMessage.senderId !== profile.userId) {
        const params = new URLSearchParams(window.location.search);
        const chatIdParams = params.get("chatId");
        if (chatMessage.globalChatRoomId === openedChatRoomId && chatIdParams) {
          if (allowReadMessageEventEmitting) {
            socketForGlobalMessage.current.emit(
              "readGlobalMessages",
              chatMessage.globalChatRoomId
            );
          }
        } else {
          newMessageChat.unreadMessageCount += 1;
        }
      }

      newMessageChat.lastMessage = {
        ...chatMessage,
        sentAt: Number(chatMessage.sentAt),
      };

      if (!isUnmounted.current) {
        setGlobalChatList((prev) => [
          newMessageChat,
          ...prev.filter((chat) => chat.id !== chatMessage.globalChatRoomId),
        ]);
      }
    };

    const messagesReadHandler = (readEvent) => {
      if (!globalChatList) {
        return;
      }

      const chatListCopy = [...globalChatList];

      const readMessagesChat = chatListCopy.find(
        (chat) => chat.id === readEvent.globalChatRoomId
      );

      if (!readMessagesChat || readMessagesChat.unreadMessageCount === 0) {
        return;
      }

      readMessagesChat.unreadMessageCount = 0;

      if (!isUnmounted.current) {
        setGlobalChatList(chatListCopy);
      }
    };

    const chatRoomDeletedHandler = async (globalChatRoomId) => {
      if (!globalChatList.some((chat) => chat.id === globalChatRoomId)) {
        return;
      }

      if (!isUnmounted.current) {
        setGlobalChatList((prev) =>
          prev.filter((chat) => chat.id !== globalChatRoomId)
        );
      }
    };

    socketForGlobalMessage.current.on("newGlobalChatRoom", newChatRoomHandler);
    socketForGlobalMessage.current.on(
      "newGlobalChatParticipant",
      newChatParticipantHandler
    );
    socketForGlobalMessage.current.on(
      "newGlobalChatMessage",
      newMessageHandler
    );
    socketForGlobalMessage.current.on(
      "globalMessagesRead",
      messagesReadHandler
    );
    socketForGlobalMessage.current.on(
      "globalChatRoomDeleted",
      chatRoomDeletedHandler
    );
    socketForGlobalMessage.current.on(
      "globalParticipantChatListUpdate",
      fetchGlobalChatList
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socketForGlobalMessage.current.off(
        "newGlobalChatRoom",
        newChatRoomHandler
      );
      socketForGlobalMessage.current.off(
        "newGlobalChatParticipant",
        newChatParticipantHandler
      );
      socketForGlobalMessage.current.off(
        "newGlobalChatMessage",
        newMessageHandler
      );
      socketForGlobalMessage.current.off(
        "globalMessagesRead",
        messagesReadHandler
      );
      socketForGlobalMessage.current.off(
        "globalChatRoomDeleted",
        chatRoomDeletedHandler
      );
      socketForGlobalMessage.current.off(
        "globalParticipantChatListUpdate",
        fetchGlobalChatList
      );
    };
  }, [
    globalChatList,
    socketForGlobalMessage,
    profile,
    openedChatRoomId,
    isPopulateChatParticipantProfiles,
    allowReadMessageEventEmitting,
  ]);

  const updateGlobalChatRoom = useCallback(
    async (accountId, chatRoom, cb) => {
      const chatListCopy = [...globalChatList];
      const updatingChatIndex = chatListCopy.findIndex(
        (chat) => chat.id === chatRoom.id
      );
      if (updatingChatIndex === -1) {
        return;
      }

      const updateResponse = await chatGraphqlInstance.updateChatChatRoomGlobal(
        {
          id: chatRoom.id,
          isArchived: chatRoom.isArchived,
        }
      );

      chatListCopy[updatingChatIndex] = {
        ...chatListCopy[updatingChatIndex],
        isArchived: updateResponse.isArchived,
        organizerName: updateResponse.organizerName,
      };

      setGlobalChatList(chatListCopy);
      if (cb && typeof cb === "function") {
        cb();
      }
    },
    [globalChatList]
  );

  return useMemo(() => {
    // TODO: investigate why sometimes there are chat duplicates
    const chats = globalChatList
      ? globalChatList.filter(
          (chat, index) =>
            globalChatList.findIndex((c) => c.id === chat.id) === index
        )
      : [];
    return [chats, { isLoading, updateGlobalChatRoom }];
  }, [globalChatList, isLoading]);
};

export const prepareLiveChatRoom = async (chatRoomParam, userProfile) => {
  const { messageCount, ...chatRoom } = { ...chatRoomParam };
  let unreadMessageCount = messageCount ? Number(messageCount) : 0;

  if (chatRoom.participants) {
    const currentUserParticipant = chatRoom.participants.find(
      (participant) => participant.userId === userProfile.userId
    );
    if (currentUserParticipant) {
      const sendMessageCount = currentUserParticipant.sendMessageCount
        ? Number(currentUserParticipant.sendMessageCount)
        : 0;
      const readMessageCount = currentUserParticipant.readMessageCount
        ? Number(currentUserParticipant.readMessageCount)
        : 0;
      unreadMessageCount =
        unreadMessageCount - sendMessageCount - readMessageCount;

      chatRoom.participants = chatRoom.participants.filter(
        (participant) => participant.userId !== userProfile.userId
      );
      chatRoom.participantProfile = chatRoom.participants[0];
    }
  }

  if (chatRoom.lastMessage) {
    chatRoom.lastMessage = {
      ...chatRoom.lastMessage,
      sendAt: Number(chatRoom.lastMessage.sendAt),
    };
  } else {
    chatRoom.lastMessage = {
      sendAt: Number(chatRoom.createdAt),
      messageText: "",
      type: CHAT_MESSAGE_TYPES.TEXT,
    };
  }

  return {
    ...chatRoom,
    unreadMessageCount,
  };
};

export const useLiveChatList = (
  { allowReadMessageEventEmitting = false, chatListRefresh = false } = {},
  filterFn
) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatId = query.get("chatId");
  const { socketForAnonymousLiveChat, openedChatRoomId } = useContext(
    AnonymousLiveChatContext
  );

  const [liveChatList, setLiveChatList] = useState(() => {
    const cache = LocalStorageService.getLiveChatRooms();
    return cache ?? undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const pendingNewChats = useRef([]);
  const { profile } = useContext(UserContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();
  const isCounsellor = profile?.type === USER_ROLES.COUNSELLOR_ROLE;

  if (!isCounsellor) {
    return [[], { isLoading: false }];
  }

  useEffect(() => {
    if (isCacheMounted.current && liveChatList) {
      LocalStorageService.setLiveChatRooms(liveChatList);
      return;
    }

    isCacheMounted.current = true;
  }, [liveChatList]);

  const fetchLiveChatList = useCallback(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const chatRoomsResponse =
      await chatGraphqlInstance.getLiveChatRoomsByUserId({
        userId: profile.userId,
      });

    let preparedChatRooms = chatRoomsResponse.map((chatRoom) =>
      prepareLiveChatRoom(chatRoom, profile)
    );

    preparedChatRooms = (await Promise.all(preparedChatRooms))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) =>
        a.lastMessage.sendAt < b.lastMessage.sendAt
          ? 1
          : b.lastMessage.sendAt < a.lastMessage.sendAt
          ? -1
          : 0
      );

    if (filterFn && typeof filterFn === "function") {
      preparedChatRooms = filterFn(preparedChatRooms);
    }

    if (!isUnmounted.current) {
      setLiveChatList(preparedChatRooms);
      setIsLoading(false);
    }
  }, [profile, chatListRefresh]);

  useEffect(() => {
    (async () => {
      fetchLiveChatList();
    })();
  }, [profile, chatListRefresh]);

  useEffect(() => {
    if (
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected ||
      !profile ||
      !openedChatRoomId ||
      !allowReadMessageEventEmitting
    ) {
      return;
    }

    if (!chatId) {
      return;
    }

    socketForAnonymousLiveChat.current.emit(
      "readLiveChatMessages",
      openedChatRoomId
    );
  }, [openedChatRoomId, socketForAnonymousLiveChat]);

  useEffect(() => {
    if (
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected ||
      !profile
    ) {
      return;
    }

    const newChatRoomHandler = async (chatRoom) => {
      if (
        liveChatList.find((chat) => chat.id === chatRoom.id) ||
        !chatRoom.participants?.find(
          (participant) => participant.userId === profile.userId
        )
      ) {
        return;
      }

      const preparedChatRoom = await prepareLiveChatRoom(chatRoom, profile);

      if (isUnmounted.current) {
        return;
      }

      setLiveChatList((prev) =>
        prev ? [preparedChatRoom, ...prev] : [preparedChatRoom]
      );
    };

    const newChatParticipantHandler = async (chatParticipant) => {
      const pendingChat = pendingNewChats.current.find(
        (v) => v.id === chatParticipant.chatRoomId
      );

      if (pendingChat) {
        if (liveChatList.find((chat) => chat.id === pendingChat.id)) {
          pendingNewChats.current = pendingNewChats.current.filter(
            (v) => v.id !== pendingChat.id
          );
          return;
        }

        const newChat = {
          ...pendingChat,
          participants: pendingChat.participants
            ? [...pendingChat.participants, chatParticipant]
            : [chatParticipant],
        };

        const chatRoomsResponse =
          await chatGraphqlInstance.getLiveChatRoomsByUserId({
            userId: profile.userId,
          });

        let preparedChatRooms = chatRoomsResponse.map((chatRoom) =>
          prepareLiveChatRoom(chatRoom, profile)
        );

        preparedChatRooms = (await Promise.all(preparedChatRooms))
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) =>
            a.lastMessage.sendAt < b.lastMessage.sendAt
              ? 1
              : b.lastMessage.sendAt < a.lastMessage.sendAt
              ? -1
              : 0
          );

        if (filterFn && typeof filterFn === "function") {
          preparedChatRooms = filterFn(preparedChatRooms);
        }

        if (!isUnmounted.current) {
          setLiveChatList(preparedChatRooms);
          setIsLoading(false);
        }
        // setChatList((prev) => [preparedChatRoom, ...prev]);
        pendingNewChats.current = pendingNewChats.current.filter(
          (v) => v.id !== newChat.id
        );
      }
    };

    const newMessageHandler = async (chatMessage) => {
      const newMessageChat = liveChatList.find(
        (chat) => chat.id === chatMessage.chatRoomId
      );

      if (!newMessageChat) {
        return;
      }

      if (chatMessage.senderId !== profile.userId) {
        const params = new URLSearchParams(window.location.search);
        const chatIdParams = params.get("chatId");
        if (chatMessage.chatRoomId === openedChatRoomId && chatIdParams) {
          if (allowReadMessageEventEmitting) {
            socketForAnonymousLiveChat.current.emit(
              "readLiveChatMessages",
              chatMessage.chatRoomId
            );
          }
        } else {
          newMessageChat.unreadMessageCount += 1;
        }
      }

      newMessageChat.lastMessage = {
        ...chatMessage,
        sendAt: Number(chatMessage.sendAt),
      };

      if (!isUnmounted.current) {
        setLiveChatList((prev) => [
          newMessageChat,
          ...prev.filter((chat) => chat.id !== chatMessage.chatRoomId),
        ]);
      }
    };

    const messagesReadHandler = (readEvent) => {
      if (!liveChatList) {
        return;
      }

      const chatListCopy = [...liveChatList];

      const readMessagesChat = chatListCopy.find(
        (chat) => chat.id === readEvent.chatRoomId
      );

      if (!readMessagesChat || readMessagesChat.unreadMessageCount === 0) {
        return;
      }

      readMessagesChat.unreadMessageCount = 0;

      if (!isUnmounted.current) {
        setLiveChatList(chatListCopy);
      }
    };

    const chatRoomDeletedHandler = async (chatRoomId) => {
      if (!liveChatList.some((chat) => chat.id === chatRoomId)) {
        return;
      }

      if (!isUnmounted.current) {
        setLiveChatList((prev) =>
          prev.filter((chat) => chat.id !== chatRoomId)
        );
      }
    };

    socketForAnonymousLiveChat.current.on(
      "newLiveChatRoom",
      newChatRoomHandler
    );
    socketForAnonymousLiveChat.current.on(
      "newLiveChatParticipant",
      newChatParticipantHandler
    );
    socketForAnonymousLiveChat.current.on(
      "newLiveChatMessage",
      newMessageHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveChatMessagesRead",
      messagesReadHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveChatRoomDeleted",
      chatRoomDeletedHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveParticipantChatListUpdate",
      fetchLiveChatList
    );
    socketForAnonymousLiveChat.current.on(
      "assignedToNewCounsellor",
      fetchLiveChatList
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socketForAnonymousLiveChat.current.off(
        "newLiveChatRoom",
        newChatRoomHandler
      );
      socketForAnonymousLiveChat.current.off(
        "newLiveChatParticipant",
        newChatParticipantHandler
      );
      socketForAnonymousLiveChat.current.off(
        "newLiveChatMessage",
        newMessageHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveChatMessagesRead",
        messagesReadHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveChatRoomDeleted",
        chatRoomDeletedHandler
      );
      socketForAnonymousLiveChat.current.off(
        "participantChatListUpdate",
        fetchLiveChatList
      );
      socketForAnonymousLiveChat.current.off(
        "assignedToNewCounsellor",
        fetchLiveChatList
      );
    };
  }, [liveChatList, socketForAnonymousLiveChat, profile, openedChatRoomId]);

  return useMemo(() => {
    const chats = liveChatList
      ? liveChatList.filter(
          (chat, index) =>
            liveChatList.findIndex((c) => c.id === chat.id) === index
        )
      : [];
    return [chats, { isLoading }];
  }, [liveChatList, isLoading]);
};

export const useLiveChatMessages = ({
  chatRoomId,
  filterFn,
  chatMessagesRefresh = false,
}) => {
  const [liveChatMessages, setLiveChatMessages] = useState(() => {
    const cache = LocalStorageService.getLiveChatMessages(chatRoomId);
    return cache ?? undefined;
  });

  const { profile } = useContext(UserContext);
  const { socketForAnonymousLiveChat } = useContext(AnonymousLiveChatContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [updatedReadBadgeId, setUpdatedReadBadgeId] = useState();
  const [date, setDate] = useState(null);
  const [isDataOver, setIsDataOver] = useState(false);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (isCacheMounted.current && liveChatMessages) {
      LocalStorageService.setLiveChatMessages(chatRoomId, liveChatMessages);
      return;
    }

    isCacheMounted.current = true;
  }, [liveChatMessages]);

  const handleLiveChatMessages = async (date = null) => {
    if (!profile) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const chatRoomMessagesResponse =
      await chatGraphqlInstance.getLiveChatChatMessageByChatRoomId(
        chatRoomId,
        date
      );

    if (chatRoomMessagesResponse.length < 20) {
      setIsDataOver(true);
    }

    const preparedChatMessagesPromises = chatRoomMessagesResponse.map(
      (chatMessage) => prepareChatMessage(chatMessage)
    );
    let preparedChatMessages = (await Promise.all(preparedChatMessagesPromises))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.sendAt < b.sendAt ? 1 : b.sendAt < a.sendAt ? -1 : 0));

    if (filterFn && typeof filterFn === "function") {
      preparedChatMessages = filterFn(preparedChatMessages);
    }

    if (!isUnmounted.current) {
      setLiveChatMessages((prev) => {
        if (initial) {
          return preparedChatMessages;
        }
        return [...prev, ...preparedChatMessages];
      });
      setIsLoading(false);
    }
  };

  const handleLoadMoreMessages = async (newDate) => {
    setDate(newDate);
    setInitial(false);
  };

  useEffect(() => {
    if (date !== null && !initial) {
      handleLiveChatMessages(date);
    }
  }, [initial, date]);

  useEffect(() => {
    (async () => {
      handleLiveChatMessages();
      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [profile, chatRoomId, chatMessagesRefresh]);

  useEffect(() => {
    if (
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected ||
      !profile
    ) {
      return;
    }

    const newMessageHandler = async (chatMessage) => {
      if (chatMessage.chatRoomId !== chatRoomId) {
        return;
      }

      const existingMessage = liveChatMessages?.find(
        (v) => v.id === chatMessage.id
      );

      if (existingMessage) {
        if (!existingMessage.isPending) {
          return;
        }

        const preparedMessage = await prepareChatMessage({
          ...chatMessage,
          attachments: existingMessage.attachments,
          isPending: false,
        });
        if (!isUnmounted.current) {
          setLiveChatMessages((prev) => [
            preparedMessage,
            ...prev.filter((v) => v.id !== preparedMessage.id),
          ]);
        }

        return;
      }

      const preparedNewMessage = await prepareChatMessage({
        ...chatMessage,
        __typename: "LiveChatChatMessage",
      });
      if (!isUnmounted.current) {
        setLiveChatMessages((prev) =>
          prev ? [preparedNewMessage, ...prev] : [preparedNewMessage]
        );
      }
    };

    const participantLiveMessagesReadHandler = async (readEvent) => {
      if (liveChatMessages && readEvent.chatRoomId === chatRoomId) {
        await chatGraphqlInstance
          .getUnreadMessageCountForLiveChat(
            readEvent.chatRoomId,
            readEvent.accountId,
            readEvent.userId
          )
          .then((response) => {
            const messageData = liveChatMessages.filter(
              (message) => message.senderId === profile.userId
            );
            if (messageData) {
              setUpdatedReadBadgeId(messageData?.[response]?.id);
            }
          });
      }
    };

    const liveParticipantDeleteMessageHandler = () => {
      handleLiveChatMessages();
    };

    socketForAnonymousLiveChat.current.on(
      "newLiveChatMessage",
      newMessageHandler
    );
    socketForAnonymousLiveChat.current.on(
      "participantLiveMessagesRead",
      participantLiveMessagesReadHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveParticipantDeleteMessage",
      liveParticipantDeleteMessageHandler
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socketForAnonymousLiveChat.current.off(
        "newLiveChatMessage",
        newMessageHandler
      );
      socketForAnonymousLiveChat.current.off(
        "participantLiveMessagesRead",
        participantLiveMessagesReadHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveParticipantDeleteMessage",
        liveParticipantDeleteMessageHandler
      );
    };
  }, [liveChatMessages, profile, chatRoomId, socketForAnonymousLiveChat]);

  const createChatMessage = useCallback(
    async (newMessage, cb) => {
      const messageId = generateUUID();
      const messageAttachments = newMessage.attachments?.length
        ? newMessage.attachments.map((attachment) => ({
            id: attachment.fileName.split(".")[0],
            accountId: newMessage.accountId,
            entityId: messageId,
            entityType: "chatAttachment",
            resourceName: attachment.originalFileName.split(".")[0],
            resourceType: attachment.fileName.split(".").pop(),
            contentType: attachment.mimetype,
            size: attachment.size,
            isLiveChat: true,
          }))
        : [];

      const preparedNewMessage = await prepareChatMessage({
        id: messageId,
        ...newMessage,
        attachments: messageAttachments,
      });

      setLiveChatMessages((prev) =>
        prev
          ? [{ ...preparedNewMessage, isPending: true }, ...prev]
          : [{ ...preparedNewMessage, isPending: true }]
      );

      const attachmentsPromises = messageAttachments.map((attachment) =>
        ResourceService.createResource(
          preparedNewMessage.accountId,
          attachment,
          true
        )
      );

      await Promise.all(attachmentsPromises);
      await chatGraphqlInstance.createLiveChatChatMessage({
        id: preparedNewMessage.id,
        accountId: preparedNewMessage.accountId,
        senderName: preparedNewMessage.senderName,
        senderId: preparedNewMessage.senderId,
        chatRoomId: preparedNewMessage.chatRoomId,
        messageText: preparedNewMessage.messageText,
        type: preparedNewMessage.type,
        sendAt: preparedNewMessage.sendAt,
      });

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    [liveChatMessages]
  );

  return useMemo(() => {
    // TODO: investigate why sometimes there are message duplicates
    const messages = liveChatMessages
      ? liveChatMessages.filter(
          (message, index) =>
            liveChatMessages.findIndex((m) => m.id === message.id) === index
        )
      : [];
    return [
      messages,
      {
        createChatMessage,
        isLoading,
        updatedReadBadgeId,
        handleLoadMoreMessages,
        isDataOver,
      },
    ];
  }, [liveChatMessages, updatedReadBadgeId, isLoading, handleLoadMoreMessages]);
};

export const prepareLiveChatRoomForStudent = async (
  chatRoomParam,
  userProfile
) => {
  const { messageCount, ...chatRoom } = { ...chatRoomParam };
  let unreadMessageCount = messageCount ? Number(messageCount) : 0;

  if (chatRoom?.liveChatChatParticipants) {
    const currentUserParticipant = chatRoom.liveChatChatParticipants.find(
      (participant) => participant.userId === userProfile.userId
    );
    if (currentUserParticipant) {
      const sendMessageCount = currentUserParticipant.sendMessageCount
        ? Number(currentUserParticipant.sendMessageCount)
        : 0;
      const readMessageCount = currentUserParticipant.readMessageCount
        ? Number(currentUserParticipant.readMessageCount)
        : 0;
      unreadMessageCount =
        unreadMessageCount - sendMessageCount - readMessageCount;

      chatRoom.liveChatChatParticipants =
        chatRoom.liveChatChatParticipants.filter(
          (participant) => participant.userId !== userProfile.userId
        );
    }
  }

  if (chatRoom.lastMessage) {
    chatRoom.lastMessage = {
      ...chatRoom.lastMessage,
      sentAt: Number(chatRoom.lastMessage.sentAt),
    };
  } else {
    chatRoom.lastMessage = {
      sentAt: Number(chatRoom.createdAt),
      messageText: "",
      type: CHAT_MESSAGE_TYPES.TEXT,
    };
  }

  return {
    ...chatRoom,
    unreadMessageCount,
    profile: chatRoom?.counsellorProfile || null,
  };
};

export const useLiveChatListForStudent = (
  {
    deviceId = null,
    allowReadMessageEventEmitting = false,
    chatListRefresh,
  } = {},
  filterFn
) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const chatId = query.get("chatId");
  const { socketForAnonymousLiveChat, openedChatRoomId } = useContext(
    StudentAnonymousLiveChatContext
  );

  const [liveChatList, setLiveChatList] = useState(() => {
    const cache = LocalStorageService.getStudentLiveChatRooms();
    return cache ?? undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const pendingNewChats = useRef([]);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  useEffect(() => {
    if (isCacheMounted.current && liveChatList) {
      LocalStorageService.setStudentLiveChatRooms(liveChatList);
      return;
    }

    isCacheMounted.current = true;
  }, [liveChatList]);

  const fetchLiveChatList = async () => {
    if (!deviceId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const chatRoomsResponse = await chatGraphqlInstance.getChatRoomByDeviceId(
      deviceId
    );

    const liveChatRoomsResponseFiltered = chatRoomsResponse.filter(
      (chatRoom) => {
        return chatRoom?.liveChatChatParticipants?.length > 1;
      }
    );

    let preparedLiveChatRooms = liveChatRoomsResponseFiltered.map((chatRoom) =>
      prepareLiveChatRoomForStudent(
        chatRoom,
        chatRoom?.liveChatChatParticipants?.find(
          (user) => user.userId === chatRoom?.organizerId
        )
      )
    );
    preparedLiveChatRooms = (await Promise.all(preparedLiveChatRooms)).sort(
      (a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.lastMessage.sentAt < b.lastMessage.sentAt
          ? 1
          : b.lastMessage.sentAt < a.lastMessage.sentAt
          ? -1
          : 0
    );

    if (filterFn && typeof filterFn === "function") {
      preparedLiveChatRooms = filterFn(preparedLiveChatRooms);
    }

    if (!isUnmounted.current) {
      setLiveChatList(preparedLiveChatRooms);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchLiveChatList();
    })();
  }, [deviceId, chatListRefresh]);

  useEffect(() => {
    if (
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected ||
      !openedChatRoomId ||
      !allowReadMessageEventEmitting
    ) {
      return;
    }
    socketForAnonymousLiveChat.current.emit(
      "readLiveChatMessages",
      openedChatRoomId
    );
  }, [openedChatRoomId, socketForAnonymousLiveChat]);

  useEffect(() => {
    if (
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected
    ) {
      return;
    }

    const newChatRoomHandler = async (chatRoom) => {
      if (liveChatList?.find((chat) => chat.id === chatRoom.id)) {
        return;
      }

      const preparedChatRoom = await prepareLiveChatRoomForStudent(
        chatRoom,
        chatRoom?.liveChatChatParticipants?.find(
          (user) => user.userId === chatRoom?.organizerId
        )
      );

      if (isUnmounted.current) {
        return;
      }

      setLiveChatList((prev) =>
        prev ? [preparedChatRoom, ...prev] : [preparedChatRoom]
      );
    };

    const newChatParticipantHandler = async (chatParticipant) => {
      const newChat = liveChatList.find(
        (v) => v.id === chatParticipant.chatRoomId
      );

      if (!newChat) {
        return;
      }

      if (
        newChat.liveChatChatParticipants.find(
          (v) => v.userId === chatParticipant.userId
        )
      ) {
        return;
      }

      newChat.liveChatChatParticipants.push(chatParticipant);

      const preparedChatRoom = await prepareLiveChatRoomForStudent(
        newChat,
        newChat?.liveChatChatParticipants?.find(
          (user) => user.userId === newChat?.organizerId
        )
      );

      if (!isUnmounted.current) {
        setLiveChatList((prev) => [
          preparedChatRoom,
          ...prev.filter((v) => v.id !== preparedChatRoom.id),
        ]);
      }
    };

    const newMessageHandler = async (chatMessage) => {
      const newMessageChat = liveChatList.find(
        (chat) => chat.id === chatMessage.chatRoomId
      );

      if (!newMessageChat) {
        return;
      }

      if (chatMessage.senderId !== chatMessage.organizerId) {
        if (chatMessage.chatRoomId === openedChatRoomId) {
          if (allowReadMessageEventEmitting) {
            socketForAnonymousLiveChat.current.emit(
              "readLiveMessages",
              chatMessage.chatRoomId
            );
          }
        } else {
          newMessageChat.unreadMessageCount += 1;
        }
      }

      newMessageChat.lastMessage = {
        ...chatMessage,
        sentAt: Number(chatMessage.sentAt),
      };

      if (!isUnmounted.current) {
        setLiveChatList((prev) => [
          newMessageChat,
          ...prev.filter((chat) => chat.id !== chatMessage.chatRoomId),
        ]);
      }
    };

    const messagesReadHandler = (readEvent) => {
      if (!liveChatList) {
        return;
      }

      const chatListCopy = [...liveChatList];

      const readMessagesChat = chatListCopy.find(
        (chat) => chat.id === readEvent.chatRoomId
      );

      if (!readMessagesChat || readMessagesChat.unreadMessageCount === 0) {
        return;
      }

      readMessagesChat.unreadMessageCount = 0;

      if (!isUnmounted.current) {
        setLiveChatList(chatListCopy);
      }
    };

    const chatRoomDeletedHandler = async (chatRoomId) => {
      if (!liveChatList.some((chat) => chat.id === chatRoomId)) {
        return;
      }

      if (!isUnmounted.current) {
        setLiveChatList((prev) =>
          prev.filter((chat) => chat.id !== chatRoomId)
        );
      }
    };

    socketForAnonymousLiveChat.current.on(
      "newLiveChatRoom",
      newChatRoomHandler
    );
    socketForAnonymousLiveChat.current.on(
      "newLiveChatParticipant",
      newChatParticipantHandler
    );
    socketForAnonymousLiveChat.current.on(
      "newLiveChatMessage",
      newMessageHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveChatMessagesRead",
      messagesReadHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveChatRoomDeleted",
      chatRoomDeletedHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveParticipantChatListUpdate",
      fetchLiveChatList
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socketForAnonymousLiveChat.current.off(
        "newLiveChatRoom",
        newChatRoomHandler
      );
      socketForAnonymousLiveChat.current.off(
        "newLiveChatParticipant",
        newChatParticipantHandler
      );
      socketForAnonymousLiveChat.current.off(
        "newLiveChatMessage",
        newMessageHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveChatMessagesRead",
        messagesReadHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveChatRoomDeleted",
        chatRoomDeletedHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveParticipantChatListUpdate",
        fetchLiveChatList
      );
    };
  }, [
    liveChatList,
    socketForAnonymousLiveChat,
    openedChatRoomId,
    allowReadMessageEventEmitting,
  ]);

  return useMemo(() => {
    // TODO: investigate why sometimes there are chat duplicates
    const chats = liveChatList
      ? liveChatList.filter(
          (chat, index) =>
            liveChatList.findIndex((c) => c.id === chat.id) === index
        )
      : [];
    return [chats, { isLoading }];
  }, [liveChatList, isLoading]);
};

export const useLiveChatMessagesForStudent = ({
  chatRoomId,
  filterFn,
  chatMessagesRefresh = false,
}) => {
  const [liveChatMessages, setLiveChatMessages] = useState(() => {
    const cache = LocalStorageService.getLiveChatMessagesForStudent(chatRoomId);
    return cache ?? undefined;
  });

  const { socketForAnonymousLiveChat } = useContext(
    StudentAnonymousLiveChatContext
  );

  const [isLoading, setIsLoading] = useState(false);
  const [initial, setInitial] = useState(true);
  const [date, setDate] = useState(null);
  const [isDataOver, setIsDataOver] = useState(false);
  const [updatedReadBadgeId, setUpdatedReadBadgeId] = useState();
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  useEffect(() => {
    if (isCacheMounted.current && liveChatMessages) {
      LocalStorageService.setLiveChatMessagesForStudent(
        chatRoomId,
        liveChatMessages
      );
      return;
    }

    isCacheMounted.current = true;
  }, [liveChatMessages]);

  const fetchLiveChatMessages = async () => {
    setIsLoading(true);
    let chatRoomMessagesResponse = [];
    if (chatRoomId) {
      chatRoomMessagesResponse =
        await chatGraphqlInstance.getLiveChatChatMessageByChatRoomId(
          chatRoomId,
          date
        );
      if (chatRoomMessagesResponse.length < 20) {
        setIsDataOver(true);
      }
    }

    const preparedChatMessagesPromises = chatRoomMessagesResponse.map(
      (chatMessage) => prepareChatMessage(chatMessage)
    );
    let preparedChatMessages = (await Promise.all(preparedChatMessagesPromises))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) =>
        Number(a.sendAt) < Number(b.sendAt)
          ? 1
          : Number(b.sendAt) < Number(a.sendAt)
          ? -1
          : 0
      );

    if (filterFn && typeof filterFn === "function") {
      preparedChatMessages = filterFn(preparedChatMessages);
    }

    if (!isUnmounted.current) {
      setLiveChatMessages((prev) => {
        if (initial) {
          return preparedChatMessages;
        }
        return [...prev, ...preparedChatMessages];
      });
    }
    setIsLoading(false);
  };

  const handleLoadMoreMessages = async (newDate) => {
    setDate(newDate);
    setInitial(false);
  };

  useEffect(() => {
    if (date !== null && !initial) {
      fetchLiveChatMessages();
    }
  }, [initial, date]);

  useEffect(() => {
    (async () => {
      fetchLiveChatMessages();
      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [chatRoomId, chatMessagesRefresh]);

  useEffect(() => {
    if (
      !chatRoomId ||
      !socketForAnonymousLiveChat?.current ||
      socketForAnonymousLiveChat.current.disconnected
    ) {
      return;
    }

    const newMessageHandler = async (chatMessage) => {
      if (chatMessage.chatRoomId !== chatRoomId) {
        return;
      }

      const existingMessage = liveChatMessages?.find(
        (v) => v.id === chatMessage.id
      );
      if (existingMessage) {
        if (!existingMessage.isPending) {
          return;
        }

        const preparedMessage = await prepareChatMessage({
          ...chatMessage,
          attachments: existingMessage.attachments,
          isPending: false,
        });
        if (!isUnmounted.current) {
          setLiveChatMessages((prev) => [
            preparedMessage,
            ...prev.filter((v) => v.id !== preparedMessage.id),
          ]);
        }

        return;
      }

      const preparedNewMessage = await prepareChatMessage({
        ...chatMessage,
        __typename: "LiveChatChatMessage",
      });
      if (!isUnmounted.current) {
        setLiveChatMessages((prev) =>
          prev ? [preparedNewMessage, ...prev] : [preparedNewMessage]
        );
      }
    };

    const participantLiveMessagesReadHandler = async (readEvent) => {
      if (liveChatMessages && readEvent.chatRoomId === chatRoomId) {
        await chatGraphqlInstance
          .getUnreadMessageCountForLiveChat(
            readEvent.chatRoomId,
            readEvent.accountId,
            readEvent.userId
          )
          .then((response) => {
            const messageData = liveChatMessages.filter(
              (message) => message.senderId === readEvent.userId
            );
            if (messageData) {
              setUpdatedReadBadgeId(messageData?.[response]?.id);
            }
          });
      }
    };

    const liveParticipantDeleteMessageHandler = () => {
      fetchLiveChatMessages();
    };

    socketForAnonymousLiveChat.current.on(
      "newLiveChatMessage",
      newMessageHandler
    );
    socketForAnonymousLiveChat.current.on(
      "participantLiveMessagesRead",
      participantLiveMessagesReadHandler
    );
    socketForAnonymousLiveChat.current.on(
      "liveParticipantDeleteMessage",
      liveParticipantDeleteMessageHandler
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socketForAnonymousLiveChat.current.off(
        "newLiveChatMessage",
        newMessageHandler
      );
      socketForAnonymousLiveChat.current.off(
        "participantLiveMessagesRead",
        participantLiveMessagesReadHandler
      );
      socketForAnonymousLiveChat.current.off(
        "liveParticipantDeleteMessage",
        liveParticipantDeleteMessageHandler
      );
    };
  }, [liveChatMessages, chatRoomId, socketForAnonymousLiveChat]);

  const createChatMessage = useCallback(
    async (newMessage, cb) => {
      setIsLoading(true);
      const messageId = generateUUID();
      const messageAttachments = newMessage.attachments?.length
        ? newMessage.attachments.map((attachment) => ({
            id: attachment.fileName.split(".")[0],
            accountId: newMessage.accountId,
            entityId: messageId,
            entityType: "chatAttachment",
            resourceName: attachment.originalFileName.split(".")[0],
            resourceType: attachment.fileName.split(".").pop(),
            contentType: attachment.mimetype,
            size: attachment.size,
            isLiveChat: true,
          }))
        : [];

      const preparedNewMessage = await prepareChatMessage({
        id: messageId,
        ...newMessage,
        attachments: messageAttachments,
      });

      setLiveChatMessages((prev) =>
        prev
          ? [{ ...preparedNewMessage, isPending: true }, ...prev]
          : [{ ...preparedNewMessage, isPending: true }]
      );
      const attachmentsPromises = messageAttachments.map((attachment) =>
        ResourceService.createResource(
          preparedNewMessage.accountId,
          attachment,
          true
        )
      );

      await Promise.all(attachmentsPromises);
      if (preparedNewMessage.chatRoomId) {
        await chatGraphqlInstance.createLiveChatChatMessage({
          id: preparedNewMessage.id,
          accountId: preparedNewMessage.accountId,
          senderName: preparedNewMessage.senderName,
          senderId: preparedNewMessage.senderId,
          chatRoomId: preparedNewMessage.chatRoomId,
          messageText: preparedNewMessage.messageText,
          type: preparedNewMessage.type,
          sendAt: preparedNewMessage.sendAt,
        });
      }

      setIsLoading(false);

      if (cb && typeof cb === "function") {
        cb();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [liveChatMessages]
  );

  return useMemo(() => {
    // TODO: investigate why sometimes there are message duplicates
    const messages = liveChatMessages
      ? liveChatMessages.filter(
          (message, index) =>
            liveChatMessages.findIndex((m) => m.id === message.id) === index
        )
      : [];
    return [
      messages,
      {
        createChatMessage,
        isLoading,
        updatedReadBadgeId,
        handleLoadMoreMessages,
        isDataOver,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveChatMessages, isLoading, handleLoadMoreMessages]);
};
