import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

import { CustomModal } from "@components/Modals";
import { Divider } from "@components/Static";
import {
  ButtonGroup,
  Button,
  Slider,
  Input,
  Calendar,
  Switch,
} from "@components/Form";
import { AppointmentReportContext } from "@contexts/AppointmentReport";
import { SuccessAnimation } from "@assets/lottie";

import styles from "./AppointmentReportModal.module.scss";
import { appointmentMeetingKindOptions } from "@utils/appointmentMeetingKindData";
import { appointmentMeetingTypeOptions } from "@utils/appointmentMeetingTypeData";
import { UserContext } from "@contexts/User";
import reportGraphqlInstance from "@services/api/report.graphql";
import CalendarIcon from "@assets/icons/Calendar";
import { useOutside } from "@hooks/useOutside";
import { format } from "date-fns";
import * as Locale from "date-fns/locale";
import i18n from "i18n";
import { AnimatePresence, motion } from "framer-motion";
import { object, string } from "yup";
import { setFieldForm } from "@utils/helpers";
import { AccordionTab } from "primereact/accordion";
import { useFormik } from "formik";
import PlusSignIcon from "@assets/icons/PlusSign";
import { sendNotify } from "@components/GlobalNotify/GlobalNotify";
import { Tooltip } from "primereact/tooltip";
import { REPORT_TOPICS_CATEGORY_TYPE } from "@utils/consts";
import { AccordionCustom } from "@components/Accordion";
import { QuestionMarkIcon } from "@assets/icons";
import CloseIcon from "@assets/icons/Close";
import ConfirmationDialog from "@components/ConfirmationDialog";

const AppointmentReportModal = ({ isOpen }) => {
  const { t } = useTranslation();
  const { createReport, appointmentReportItem, createAppointmentReportItem } =
    useContext(AppointmentReportContext);
  const { profile: currentProfile } = useContext(UserContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedMeetingTypeOptions, setSelectedMeetingTypeOptions] = useState(
    []
  );
  const [selectedMeetingKindOptions, setSelectedMeetingKindOptions] = useState(
    []
  );
  const [worryLevel, setWorryLevel] = useState("0");
  const [noteComment, setNoteComment] = useState("");
  const [feedbackAllbry, setFeedbackAllbry] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isAdvanceQuick, setIsAdvanceQuick] = useState(false);
  const [isAnonymousReport, setIsAnonymousReport] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [studentName, setStudentName] = useState("");
  const today = useMemo(() => new Date());
  const [date, setDate] = useState(today);
  const [showCalendar, setShowCalendar] = useState(false);
  const [addReportTopicsLoading, setAddReportTopicsLoading] = useState(false);
  const [reportTopicsCategory, setReportTopicsCategory] = useState([]);
  const [isOpenTopicAddModal, setIsOpenTopicAddModal] = useState(false);
  const [selectTopicCategoryId, setSelectTopicCategoryId] = useState(null);
  const [activeIndexTopicCategory, setActiveIndexTopicCategory] =
    useState(null);
  const [isDigitalMeeting, setIsDigitalMeeting] = useState(false);
  const [showMeetingKind, setShowMeetingKind] = useState(false);
  const [showWorryLevelModal, setShowWorryLevelModal] = useState(false);
  const [
    showConfirmationDialogForCloseReportModalWithoutChatReportCreate,
    setShowConfirmationDialogForCloseReportModalWithoutChatReportCreate,
  ] = useState(false);
  const calendarRef = useRef(null);
  useOutside(calendarRef, setShowCalendar);

  const getAllReportTopicsCategory = () => {
    reportGraphqlInstance
      .getAllReportTopicsCategory(REPORT_TOPICS_CATEGORY_TYPE.COUNSELLOR)
      .then((res) => {
        if (res) {
          setReportTopicsCategory(res);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllReportTopicsCategory();
  }, []);

  useEffect(() => {
    if (appointmentReportItem) {
      appointmentReportItem.type == "anonymous_quick_report"
        ? setIsAnonymousReport(true)
        : setIsAnonymousReport(false);

      if (
        appointmentReportItem.type == "chat" ||
        appointmentReportItem.type == "appointment"
      ) {
        setIsDigitalMeeting(true);
        setSelectedMeetingKindOptions([
          appointmentMeetingKindOptions.find(
            (x) => x.value == "Digital meeting"
          ),
        ]);
      } else {
        setIsDigitalMeeting(false);
        setSelectedMeetingKindOptions([]);
      }
    }
  }, [appointmentReportItem]);

  useEffect(() => {
    if (isAnonymousReport && currentProfile) {
      reportGraphqlInstance
        .getReportAttributes(currentProfile.accountId)
        .then((res) => {
          setAttributes(res);
        });
    }
  }, [isAnonymousReport]);

  const onSelectedAttributesChange = (option, x) => {
    const selectedItem = JSON.parse(JSON.stringify(selectedAttributes));
    if (!selectedItem[x.attributeName.value]) {
      selectedItem[x.attributeName.value] = [];
    }
    let found = false;
    const newOptions = selectedItem[x.attributeName.value]
      .map((selectedOption) => {
        if (selectedOption.value === option.value) {
          found = true;
          return null;
        }
        return selectedOption;
      })
      .filter((selectedOption) => !!selectedOption);

    if (!found) {
      newOptions.push(option);
    }
    selectedItem[x.attributeName.value] = newOptions;
    setSelectedAttributes(selectedItem);
  };

  const onChangeWorryLevel = (event) => {
    setWorryLevel(event.target.value);
  };

  const onSelectedOptionsChange = (option) => {
    let found = false;
    const newOptions = selectedOptions
      .map((selectedOption) => {
        if (selectedOption.value === option.value) {
          found = true;
          return null;
        }

        return selectedOption;
      })
      .filter((selectedOption) => !!selectedOption);

    if (!found) {
      newOptions.push(option);
    }

    setSelectedOptions(newOptions);
  };

  const onSelectedMeetingTypeOptionsChange = (option) => {
    let found = false;
    const newOptions = selectedMeetingTypeOptions
      .map((selectedMeetingTypeOption) => {
        if (selectedMeetingTypeOption.value === option.value) {
          found = true;
          return null;
        }
        return selectedMeetingTypeOption;
      })
      .filter((selectedMeetingTypeOption) => !!selectedMeetingTypeOption);

    if (!found) {
      newOptions.push(option);
    }
    setSelectedMeetingTypeOptions(newOptions);
  };

  const onSelectedMeetingKindOptionsChange = (option) => {
    let found = false;
    const newOptions = selectedMeetingKindOptions
      .map((selectedMeetingTypeOption) => {
        if (selectedMeetingTypeOption.value === option.value) {
          found = true;
          return null;
        }
      })
      .filter((selectedMeetingTypeOption) => !!selectedMeetingTypeOption);

    if (!found) {
      newOptions.push(option);
    }
    setSelectedMeetingKindOptions(newOptions);
  };
  let selectedMeetingKindOption = "";
  for (let x of selectedMeetingKindOptions) {
    selectedMeetingKindOption = x.value;
  }

  const saveAppointmentReport = async () => {
    setIsLoading(true);
    setIsAdvanceQuick(true);
    setIsLoading(false);
  };

  const saveAllReport = async () => {
    setIsSaving(true);
    setIsSaved(true);
    setIsAdvanceQuick(false);
    try {
      let attributesData = {};
      for (const [key, value] of Object.entries(selectedAttributes)) {
        Object.assign(attributesData, {
          [t(key)]: value.map((v) => t(v.value)),
        });
      }

      const topics = selectedOptions.reduce((result, item) => {
        const categoryIndex = result.findIndex(
          (entry) => entry.category === item.categoryValue
        );
        if (categoryIndex === -1) {
          result.push({
            category: item.categoryValue,
            value: [item.value],
          });
        } else {
          result[categoryIndex].value.push(item.value);
        }
        return result;
      }, []);

      await createReport({
        data: {
          topics,
          worryLevel,
          meetingKind: selectedMeetingKindOption,
          meetingType: selectedMeetingTypeOptions.map((option) => option.value),
          note: noteComment,
          feedback: feedbackAllbry,
          studentName,
          attributes: attributesData,
          createdAt: new Date(date).getTime(),
          updatedAt: new Date(date).getTime(),
        },
      });
      setIsSaving(false);
      setSelectedOptions([]);
      setWorryLevel(0);
      setSelectedMeetingTypeOptions([]);
      setSelectedMeetingKindOptions([]);
      setNoteComment("");
      setFeedbackAllbry("");
      setStudentName("");
      setSelectedAttributes({});
      setIsAnonymousReport(false);
      setIsSaved(true);
      setDate(today);
      setShowMeetingKind(false);
      setActiveIndexTopicCategory(null);
    } catch (err) {
      setIsSaving(false);
    }
  };

  const closeSavedModal = () => {
    createAppointmentReportItem(null);
    setIsSaved(false);
  };
  const closeModal = () => {
    if (appointmentReportItem?.isManualReport) {
      createAppointmentReportItem(null);
      setIsAdvanceQuick(false);
      setSelectedOptions([]);
      setWorryLevel(0);
      setSelectedMeetingTypeOptions([]);
      setSelectedMeetingKindOptions([]);
      setNoteComment("");
      setFeedbackAllbry("");
      setStudentName("");
      setSelectedAttributes({});
      setIsAnonymousReport(false);
      setDate(today);
      setShowMeetingKind(false);
      setActiveIndexTopicCategory(null);
    } else {
      setIsSaved(false);
    }
  };

  const onContinue = async () => {
    setIsLoading(true);
    setIsAnonymousReport(false);
    setIsLoading(false);
  };

  const onChangeDate = (newDate) => {
    setDate(newDate);
  };

  const closeAddReportTopicsModal = () => {
    setIsOpenTopicAddModal(false);
    setSelectTopicCategoryId(null);
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    setErrors,
    initialValues,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: object().shape({
      name: string().required("common.requiredField"),
      description: string().required("common.requiredField"),
    }),
    onSubmit: (form) => onSubmit(form),
  });

  const onSubmit = async (data) => {
    setAddReportTopicsLoading(true);
    const value = data.name.trim().replace(/ /g, "_").toLowerCase();
    const payload = {
      ...data,
      name: data.name.trim(),
      categoryId: selectTopicCategoryId,
      accountId: currentProfile.accountId,
      value: value,
    };

    try {
      reportGraphqlInstance.createReportTopics(payload).then((res) => {
        if (res) {
          resetForm();
          closeAddReportTopicsModal();
          setAddReportTopicsLoading(false);
          sendNotify(t("reports.addTopic.success"), "success");
          getAllReportTopicsCategory();
        }
      });
    } catch (err) {
      sendNotify(t("reports.addTopic.error"), "error");
      setAddReportTopicsLoading(false);
      closeAddReportTopicsModal();
    }
  };

  const topicActiveTabExpand = () => {
    setActiveIndexTopicCategory([...Array(reportTopicsCategory.length).keys()]);
  };

  const topicActiveTabCollapse = () => {
    setActiveIndexTopicCategory(null);
  };

  const renderConfirmationDialogBodyForCloseReportModalWithoutChatReportCreate =
    useMemo(
      () => (
        <>
          <p>{t("reports.closeReportModalWithoutChatReportConfirmation")}</p>
        </>
      ),
      [showConfirmationDialogForCloseReportModalWithoutChatReportCreate]
    );

  const handleCloseModalWithoutChatReportCreate = () => {
    setShowConfirmationDialogForCloseReportModalWithoutChatReportCreate(false);
    createAppointmentReportItem(null);
  };

  const anonymousReportStep = () => {
    if (!isLoading && isAnonymousReport) {
      return (
        <>
          <div className="flex flex-col">
            <div className="h-28 px-12 flex flex-row items-center">
              <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight m-0">
                {t("counsellorReport.anonymousReport.title")}
              </span>
            </div>
            <Divider />
            <div
              className={clsx("flex flex-col px-12", styles.reportModalSize)}
            >
              <div className="pt-3">
                <span className="text-dawn-gray text-xlg font-bold">
                  {t("counsellorReport.anonymousReport.selectAttributeTitle")}
                </span>
                {attributes?.length ? (
                  attributes.map((x, index) => {
                    const result = selectedAttributes[x.attributeName] || [];
                    return (
                      x.attributeValue?.length && (
                        <React.Fragment key={`attribute-${index}`}>
                          <span className="text-dawn-gray text-xlg block p-2 mt-2">
                            {x.attributeName}
                          </span>
                          <ButtonGroup
                            className="flex flex-row flex-wrap gap-2"
                            variant="report"
                            variantSelected="reportSelected"
                            options={x.attributeValue.map((y) => ({
                              label: y,
                              value: y,
                            }))}
                            selectedOptions={result}
                            onChange={(e) => {
                              onSelectedAttributesChange(e, {
                                ...x,
                                attributeName: {
                                  label: x.attributeName,
                                  value: x.attributeName,
                                },
                              });
                            }}
                          />
                        </React.Fragment>
                      )
                    );
                  })
                ) : (
                  <span className="text-dawn-gray text-xlg block p-2 mt-2 text-sm">
                    {t("counsellorReport.anonymousReport.attributeNotFound")}
                  </span>
                )}
              </div>
              <div className="mt-5 mb-3">
                <span className="text-dawn-gray text-xlg font-bold">
                  {t("counsellorReport.anonymousReport.studentName")}
                </span>
                <div className="mt-3 flex justify-center items-center">
                  <Input
                    className="w-full"
                    value={studentName}
                    onChange={setStudentName}
                  />
                </div>
              </div>
              <div className="absolute left-0 bottom-0 w-full py-5 px-12 z-10 shadow-top flex flex-row justify-end items-center">
                <Button
                  variant="edit"
                  className="rounded-full w-44 py-4 border-transparent border-solid border"
                  onClick={onContinue}
                  isLoading={isSaving}
                >
                  <span className="text-white">{t("common.continue")}</span>
                </Button>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const renderSteps = () => {
    if (!isLoading && isAdvanceQuick)
      return (
        <div className="flex flex-col">
          <div className="h-16 flex flex-row ">
            <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight m-0">
              {t("reports.title2")}
            </span>
          </div>
          <Divider />
          <div className={clsx("flex flex-col", styles.reportModalSize)}>
            <div className="pt-3">
              <span className="text-dawn-gray text-lg font-bold">
                {t("reports.selectMeetingType")}
              </span>
              <div className="mt-3">
                <ButtonGroup
                  className="flex flex-row flex-wrap gap-2"
                  variant="report"
                  variantSelected="reportSelected"
                  options={appointmentMeetingTypeOptions}
                  selectedOptions={selectedMeetingTypeOptions}
                  onChange={onSelectedMeetingTypeOptionsChange}
                />
              </div>
            </div>
            {!isDigitalMeeting && (
              <div className="pt-3">
                <div style={{ marginBottom: "-15px" }}>
                  <span className="text-dawn-gray text-lg font-bold align-sub">
                    {t("reports.selectMeetingKind")}{" "}
                    <span className="text-red text-3xl align-top">*</span>
                  </span>
                </div>
                <div className="flex gap-3">
                  <span className="text-dawn-gray text-base font-medium self-center">
                    {t("reports.meetingKind.allbryMeeting")}
                  </span>
                  <Switch
                    isChecked={showMeetingKind}
                    onToggle={(e) => {
                      setShowMeetingKind(e);
                      if (e) {
                        setSelectedMeetingKindOptions([
                          appointmentMeetingKindOptions.find(
                            (x) => x.value == "Digital meeting"
                          ),
                        ]);
                      } else {
                        setSelectedMeetingKindOptions([]);
                      }
                    }}
                  />
                </div>
                {!showMeetingKind && (
                  <div className="mt-3">
                    <span className="text-dawn-gray text-opacity-50 text-base font-small-poppins self-center">
                      {t("reports.meetingKind.allbryMeetingIfNot")}
                    </span>
                    <ButtonGroup
                      className="flex flex-row flex-wrap gap-2"
                      variant="report"
                      variantSelected="reportSelected"
                      options={appointmentMeetingKindOptions.filter(
                        (x) => x.value !== "Digital meeting"
                      )}
                      selectedOptions={selectedMeetingKindOptions}
                      onChange={onSelectedMeetingKindOptionsChange}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="mt-3 mb-3">
              <span className="text-dawn-gray text-xlg font-bold">
                {t("reports.noteComment", {
                  questionNo: isDigitalMeeting ? 5 : 6,
                })}
              </span>
              <div className="mt-3 flex justify-center items-center">
                <Input
                  className="w-full"
                  value={noteComment}
                  onChange={setNoteComment}
                />
              </div>
            </div>
            <div className="mt-3 mb-3">
              <span className="text-dawn-gray text-xlg font-bold">
                {t("reports.feedbackAllbry", {
                  questionNo: isDigitalMeeting ? 6 : 7,
                })}
              </span>
              <div className="mt-3 flex justify-center items-center">
                <Input
                  className="w-full"
                  value={feedbackAllbry}
                  onChange={setFeedbackAllbry}
                />
              </div>
            </div>
          </div>
          <div className="absolute left-0 bottom-0 w-full py-5 px-12 z-10 shadow-top flex flex-row justify-end items-center">
            <Button
              variant="edit"
              className="rounded-full w-44 py-4 border-transparent border-solid border"
              isLoading={isSaving}
              onClick={saveAllReport}
              isDisabled={selectedMeetingKindOptions.length == 0}
            >
              <span className="text-white">{t("common.save")}</span>
            </Button>
          </div>
        </div>
      );
    if (isSaved) {
      return (
        <div className="flex flex-col justify-center items-center">
          <div className="w-96 h-56">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: SuccessAnimation,
                rendererSettings: {
                  viewBoxSize: "90 50 760 500",
                },
              }}
              ariaRole="none"
            />
          </div>
          <h1 className="font-bold-poppins text-3xl leading-title text-dawn-gray tracking-tight mb-2">
            {t("reports.participationThanks.title")}
          </h1>
          <span className="text-base text-cloudy-gray max-w-xs text-center mb-12">
            {t("reports.participationThanks.details")}
          </span>
          <Button
            variant="edit"
            className="rounded-full py-4 px-24"
            onClick={closeSavedModal}
            isLoading={isSaving}
          >
            <span className="text-white">
              {t("reports.participationThanks.close")}
            </span>
          </Button>
        </div>
      );
    }

    if (!isLoading && isAnonymousReport) return anonymousReportStep();
    return (
      <div className="flex flex-col">
        <div className="h-28 px-12 flex flex-row items-center justify-between">
          <span className="font-bold-poppins text-3xl text-dawn-gray tracking-tight m-0">
            {t("reports.title")}
          </span>
          <span
            className="cursor-pointer"
            onClick={() =>
              setShowConfirmationDialogForCloseReportModalWithoutChatReportCreate(
                true
              )
            }
          >
            <CloseIcon />
          </span>
        </div>
        <Divider />
        <div className={clsx("flex flex-col px-12", styles.reportModalSize)}>
          <div className="pt-9">
            <span className="text-dawn-gray text-xl font-bold">
              {t("reports.selectDate")}
            </span>
            <div className="mt-2">
              <div className="flex pt-3 flex-row relative">
                <Button
                  onClick={() => setShowCalendar(!showCalendar)}
                  className={clsx(
                    "justify-center items-center bg-silver-light rounded py-5 px-4 border border-solid border-transparent",
                    showCalendar && "pointer-events-none"
                  )}
                  variant="custom"
                >
                  <span className="text-dawn-gray mr-4">
                    {format(date, "EEEE, MMMM d", {
                      locale: Locale[i18n.language],
                    })}
                  </span>
                  <CalendarIcon width={22} height={20} />
                </Button>
                <AnimatePresence>
                  {showCalendar && (
                    <motion.div
                      ref={calendarRef}
                      className={clsx(
                        "absolute left-60 rounded-md z-10 flex flex-row bg-milk",
                        styles.calendarShadow
                      )}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, translateY: "0.625rem" }}
                      exit={{ opacity: 0, translateY: "0.625rem" }}
                    >
                      <Calendar
                        withoutShadow
                        maxDate={today}
                        value={date}
                        onChange={onChangeDate}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <div className="pt-9">
            <span className="text-dawn-gray text-xl font-bold">
              {t("reports.selectTopics")}
            </span>
            <div className={clsx("mt-9")}>
              <div className="text-right mb-4">
                <button onClick={topicActiveTabExpand}>
                  <span className="text-dawn-gray font-medium-poppins">
                    {t("reports.addTopic.expandTab")}
                  </span>
                </button>{" "}
                &nbsp;&nbsp;/ &nbsp;&nbsp;
                <button onClick={topicActiveTabCollapse}>
                  <span className="text-dawn-gray font-medium-poppins">
                    {t("reports.addTopic.collapseTab")}
                  </span>
                </button>
                <span className="text-dawn-gray font-medium-poppins">
                  &nbsp;&nbsp;{t("reports.addTopic.all")}
                </span>
              </div>
              <AccordionCustom
                multiple
                activeIndex={activeIndexTopicCategory}
                onTabChange={(e) => setActiveIndexTopicCategory(e.index)}
              >
                {reportTopicsCategory.length > 0 &&
                  reportTopicsCategory?.map((category, i) => (
                    <AccordionTab
                      header={
                        i18n.language == "enUS"
                          ? category?.nameENG
                          : category?.nameSW
                      }
                      key={i}
                    >
                      {category?.reportTopics.map((topic, index) => (
                        <div
                          className={clsx("mx-1 inline-block")}
                          key={index}
                          onClick={() => {
                            onSelectedOptionsChange({
                              ...topic,
                              categoryValue: category.value,
                            });
                          }}
                        >
                          <Tooltip
                            target={`.without${index}`}
                            position={"top"}
                            style={{ maxWidth: "400px" }}
                          />
                          <div
                            className={clsx(
                              "mt-2",
                              styles.OptionsBox,
                              selectedOptions.some(
                                (option) => option.value === topic.value
                              )
                                ? styles.selectedBox
                                : ""
                            )}
                          >
                            <div className="justify-center py-8 rounded-3xl">
                              <h5>
                                {i18n.language == "enUS"
                                  ? topic.nameENG
                                  : topic.nameSW}
                              </h5>{" "}
                              &nbsp;&nbsp;
                              <i
                                className={`without${index} proceed pi pi-question-circle cursor-pointer`}
                                data-pr-tooltip={
                                  i18n.language == "enUS"
                                    ? topic.descriptionENG
                                    : topic.descriptionSW
                                }
                                data-pr-position="top"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className={clsx("mx-1 inline-block")}
                        onClick={() => {
                          setIsOpenTopicAddModal(true);
                          setSelectTopicCategoryId(category.id);
                        }}
                      >
                        <div className={clsx("mt-2", styles.OptionsBox)}>
                          <div className="justify-center py-8 rounded-3xl">
                            <h5 className="flex">
                              {t("reports.addTopic.button")} &nbsp;&nbsp;{" "}
                              <PlusSignIcon />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  ))}
              </AccordionCustom>
            </div>
          </div>
          <div className="mt-11 mb-16">
            <span className="text-dawn-gray text-xl font-bold">
              {t("reports.concernScale")}
            </span>
            <div className="mt-6 flex justify-center items-center">
              <Slider
                max={5}
                className="w-full"
                value={worryLevel}
                onChange={onChangeWorryLevel}
              />
            </div>
            <div className="flex flex-row justify-center mt-3 items-center">
              <span className="text-dawn-gray text-opacity-50 rounded-md cursor-pointer font-bold text-center">
                {t(
                  `reports.worriedLevel.level${
                    worryLevel && worryLevel?.replace(".", "_")
                  }`
                )}
                <span
                  onClick={() => {
                    setShowWorryLevelModal(true);
                  }}
                >
                  <i
                    className="without0 proceed pi pi-question-circle text-dawn-gray cursor-pointer cursor-pointer ml-2 align-text-bottom"
                    style={{ fontSize: "18px", fontWeight: 300 }}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="absolute left-0 bottom-0 w-full py-3 px-12 z-10 shadow-top flex flex-row justify-end items-center">
          <Button
            variant="edit"
            className="rounded-full w-44 py-4 border-transparent border-solid border"
            onClick={saveAppointmentReport}
            isLoading={isSaving}
            isDisabled={!selectedOptions?.length}
          >
            <span className="text-white">{t("common.next")}</span>
          </Button>
        </div>
        <CustomModal
          openModal={isOpenTopicAddModal}
          setOpenModal={closeAddReportTopicsModal}
          hideCloseButton={false}
          className={clsx(
            "bg-milk rounded-xl flex justify-center h-full	py-8",
            styles.reportModal
          )}
        >
          <form onSubmit={handleSubmit}>
            <h4 className="text-3xl text-center font-bold-poppins text-dawn-gray mb-14 max-w-2xl">
              {t("reports.addTopic.title")}
            </h4>
            <div className="w-full max-w-sm flex flex-col mb-16">
              <Input
                onChange={(val) =>
                  setFieldForm(setFieldValue, setErrors, "name", val)
                }
                placeholder="reports.addTopic.name"
                className="mb-6"
                value={values.name}
                error={errors.name}
              />
              <Input
                onChange={(val) =>
                  setFieldForm(setFieldValue, setErrors, "description", val)
                }
                placeholder="reports.addTopic.description"
                value={values.description}
                error={errors.description}
              />
            </div>
            <Button
              isLoading={addReportTopicsLoading}
              type="submit"
              className="rounded-full m-auto w-52 py-2"
              isDisabled={
                !!Object.keys(errors).length || initialValues === values
                  ? true
                  : addReportTopicsLoading
              }
            >
              <span className="text-white font-medium-poppins">
                {t("common.add")}
              </span>
            </Button>
          </form>
        </CustomModal>
        <CustomModal
          openModal={showWorryLevelModal}
          setOpenModal={() => setShowWorryLevelModal(false)}
          className="bg-milk rounded-xl pt-4 pb-8 w-130"
        >
          <div className="flex flex-col justify-center items-center">
            <span className="text-dawn-gray text-2xl font-bold mb-4 px-8">
              {t("reports.worriedLevel.title")} - {worryLevel}
            </span>
            <Divider />
            <div className="mt-6 px-8 w-full">
              <span className="text-dawn-gray text-opacity-50 rounded-md cursor-pointer whitespace-pre-line">
                {t(
                  `reports.worriedLevel.details${
                    worryLevel && worryLevel?.replace(".", "_")
                  }`
                )}
              </span>
            </div>
          </div>
        </CustomModal>

        {/* Close the report modal without chat report create */}
        <ConfirmationDialog
          showConfirmDialog={
            showConfirmationDialogForCloseReportModalWithoutChatReportCreate
          }
          bodyTemplate={
            renderConfirmationDialogBodyForCloseReportModalWithoutChatReportCreate
          }
          positiveButtonLabel={t("common.yes")}
          positiveButtonDisable
          negativeButtonLabel={t("common.no")}
          handleCloseEvent={() =>
            setShowConfirmationDialogForCloseReportModalWithoutChatReportCreate(
              false
            )
          }
          handleYesEvent={() => handleCloseModalWithoutChatReportCreate()}
          width="25vw"
        />
      </div>
    );
  };

  return (
    <CustomModal
      openModal={isOpen || isSaved}
      setOpenModal={closeModal}
      hideCloseButton={!isAdvanceQuick}
      className={clsx(
        "bg-milk rounded-xl",
        styles.reportModal,
        isAdvanceQuick ? "p-8" : "pb-28",
        isSaved ? "p-8" : "pb-28"
      )}
    >
      {renderSteps()}
    </CustomModal>
  );
};

export default AppointmentReportModal;
